<template>
  <v-container>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-card-title>
        <img
          class="mx-auto"
          :src="require('@/assets/CompanyTitle.png')"
          width="150"
          @click="clickMenu()"
        />
      </v-card-title>
      <div class="d-flex align-left justify-space-around" >
        <div v-if="data" class="text-left" v-html="data">
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      this.$router.push({ name: "PageErrorNotFound" });
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp.value;
      
    };
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      var key = this.$route.params.key;
      this.api.url =`${this.$api.servers.sso}/api/v1/${this.$_getLocale()}/data/legalDocument/${key}`;
      this.$api.fetch(this.api);
    },
    clickMenu() {
      this.$router.push({ name: "PageMainHome" });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>